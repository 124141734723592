import React, { useContext, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { DrinkContext, UserContext, GraphDataPointContext } from '../App.js';
import { TransformDrinkArrayToGraph } from '../data/drinkClass.js';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function DrinkGraph() {
  const { drunkenDrinks, setDrunkenDrinks } = useContext(DrinkContext);
  const { weight, gender } = useContext(UserContext);
  const { graphDataPoints, setGraphDataPoints} = useContext(GraphDataPointContext)
  

  useEffect(() => {
    if (drunkenDrinks.length > 0) {
      setGraphDataPoints(TransformDrinkArrayToGraph(drunkenDrinks, weight, gender));
    }
   
  }, [drunkenDrinks]);

  // Daten für Uhrzeit und Datum vorbereiten
  const labelsUhrzeit = graphDataPoints.map((dp) => new Date(dp.uhrzeit).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
  
  const labelsDatum = graphDataPoints.map((dp, index, array) => {
    const currentDate = new Date(dp.uhrzeit).toLocaleDateString([], { day: '2-digit', month: '2-digit' });
    // Zeige das Datum nur bei einem neuen Tag
    return index === 0 || new Date(array[index - 1].uhrzeit).toDateString() !== new Date(dp.uhrzeit).toDateString()
      ? currentDate
      : '';
  });

  const promilleValues = graphDataPoints.map((dp) => dp.promille);

  // Konfiguration für den Chart
  const chartData = {
    labels: labelsUhrzeit,
    datasets: [
      {
        label: 'Promilleverlauf',
        data: promilleValues,
        borderColor: 'rgba(75, 192, 192, 1)',
        fill: false,
        tension: 0.1,
        xAxisID: 'x', // Ordnet diesen Datensatz der Uhrzeit-Achse zu
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'white',
        },
      },
      title: {
        display: true,
        text: 'Alkoholgehalt über die Zeit',
        color: 'white',
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Uhrzeit',
          color: 'white',
        },
        ticks: {
          color: 'white',
        },
      },
      xDate: {
        position: 'bottom',
        type: 'category',
        offset: true, // Sorgt dafür, dass die Achsen nicht überlappen
        labels: labelsDatum, // Zeigt das Datum nur einmal pro Tag an
        title: {
          display: true,
          text: 'Datum',
          color: 'white',
        },
        ticks: {
          color: 'white',
          callback: function (value, index) {
            return labelsDatum[index] || null; // Nur einmal pro Tag anzeigen, sonst leer lassen
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Promille',
          color: 'white',
        },
        ticks: {
          color: 'white',
        },
      },
    },
  };

  return (
    <div className='GraphParent'>
      <div style={{ color: "white" }}>
        <h2>Promilleverlauf</h2>
      </div>
      <div className='Graph'>
        {/* Plot wird nur gerendert, wenn Daten vorhanden sind */}
        {drunkenDrinks.length > 0 && <Line data={chartData} options={options} />}
      </div>
    </div>
  );
}