import '../App.css';
import React, { useState, useContext } from 'react';
import { UserContext, ErrorContext } from '../App';

export default function UserData() {

  const { user, setUser, weight, setWeight, gender, setGender, isUserDataSubmitted, setUserDataSubmitted } = useContext(UserContext)
  const { errorHandler, setErrorHandler } = useContext(ErrorContext)

  const [menuState, setMenuState] = useState(false)

  const handleSubmit = () => {
    if (weight == "" || gender == "" || user == "") {
      setErrorHandler({
        errorText: "Es muss ein Name, ein Gewicht und eine Geschlecht eingegeben werden!",
        errorState: true,
        errorType: "Unvollständiges Nutzerprofil",
        errorColor: "red"
      })
    }
    else if (weight != parseInt(weight, 10) || weight <= 20 || weight >= 1000) {
      setErrorHandler({
        errorText: "Gewicht muss eine Zahl größer 20 Kg und kleiner 1000 Kg sein!",
        errorState: true,
        errorType: "Ungültige Werte",
        errorColor: "red"
      })
    }
    else {
      setUserDataSubmitted(true);
    }
  };

  const handleSubmit2 = () => {
    setUserDataSubmitted(false);
  }
  const handleMenuButton = () => {
    setMenuState(!menuState)
  }

  return (
    <div>
      <div className='UserHeader'>
        {!isUserDataSubmitted ? (
          <div className='UserHeaderChildren'>
            <div>
              <input className='input-style1' type='text' id='user' name='user' placeholder='Bitte Name eingeben' value={user} onChange={(e) => setUser(e.target.value.slice(0, 20))} />
            </div>
            <div className='Gap10'>
              <input className='input-style1' type='text' id='weight' name='weight' placeholder='Bitte Gewicht in Kg eingeben' value={weight} onChange={(e) => setWeight(e.target.value)} />
              <t2>
                <strong>Kg</strong>
              </t2>
            </div>
            <div>
              <select className='input-style2' id="dropdown" name="options" value={gender} onChange={(e) => setGender(e.target.value)}>
                <option value="">Geschlecht wählen...</option>
                <option value="männlich">männlich</option>
                <option value="weiblich">weiblich</option>
              </select>
            </div>
            <div>
              <button className='button-42' onClick={handleSubmit}>Bestätigen</button>
            </div>
          </div>
        ) : (
          <div className='UserHeaderChildren2'>
            <h2>
              Hallo {user} !
            </h2>
            <button className='button-42' onClick={handleSubmit2} >
              Nutzerprofil
            </button>
          </div>
        )}
      </div>

      <div className='Body'> {isUserDataSubmitted == false &&
        <div className='InfoUndDisclaimerUndExpla'>
          <div className='Titel'>
            <h1> Promillerechner </h1>
          </div>
          <div className='StartInfo'>
            <h1> Kurzanleitung </h1>
            <ol className='Punkte'>
              <li><strong> Nutzerdaten eingeben</strong></li>
              <li><strong> Getränk auswählen</strong></li>
              <li><strong> Uhrzeit und Datum angeben oder übernehmen</strong></li>
              <li><strong> Informationen ablesen</strong></li>
            </ol>
          </div>
          <div className='Disclaimer'>
            <h1> Disclaimer </h1>
            <t1>
              Die Applikation ist das Ergebnis eines Projektes der DHBW Stuttgart 2024 und somit eine private, nicht kommerzielle Anwendung.
              Diese Anwendung erhebt keinen Anspruch auf die Richtigkeit der Daten!
              Die Berechnungen beruhen auf annähernden Formeln und sind somit nicht wissenschaftlich oder rechtlich belastbar.
              Erfasste Daten werden lediglich lokal verarbeitet und nicht abgespeichert.
            </t1>
          </div>
          <div className='ExplicitExpla'>
            <div>
              <button className='button-42' onClick={handleMenuButton}> Detailanleitung </button>
            </div>
            <div> {menuState == true &&
              <div>
                  <h1> Anleitung </h1>
                  <h3> Nutzerprofil </h3>
                  <p>
                  In der Kopfzeile der Nutzerprofil-Ansicht gibt der Nutzer einen User-Name und ein Gewicht in Kilogramm an. 
                  Zudem wird ein biologisches Geschlecht, männlich oder weiblich, aus einem Dropdownmenü ausgewählt. 
                  Die eingegebenen Daten werden nicht gespeichert und nur lokal verarbeitet.
                  Der Nutzername soll dem Nutzer eine Personalisierung der Applikation ermöglichen und erscheint in der Kopfzeile der Getränkeauswahl-Ansicht und Betriebsansicht als eine Grußformel. 
                  </p>
                  <h3> Getränkeauswahl </h3>
                  <p>
                  Über einen Klick auf „Nutzerprofil“ kann der Nutzer zurück in die Nutzerprofil-Ansicht gelangen. Die eingegebenen Getränke werden dabei gespeichert.
                  Das erste Dropdownmenü wählt den Getränketyp. Das zweite Dropdownmenü wählt basierend auf der Auswahl im ersten Dropdownmenü die Getränkeart.
                  Die Uhrzeit und das Datum kann über das Feld zur Datumseingabe in der Getränkeauswahl ausgewählt werden. Durch Klicken auf „Bestätigen!“ wird das ausgewählte Getränk und die Zeitangaben der Berechnung hinzugefügt.
              	  </p>
                  <h3> Kalkulation </h3>
                  <p>
                  Die Kalkulation des Promillewertes erfolgt mittels Gewicht, Geschlecht und Alkoholgehalt an Hand der Widmark-Formel. 
                  Diese berechnet den maximalen Promillewert. Die Applikation stellt den Verlauf darauf basierend in einem Graphen dar. 
                  Die eingegebenen Getränke werden in der Informationsbox „Ausgewählte Getränke“ wiedergegeben. Dort kann der Nutzer Getränkeeingaben über Klicken auf „Löschen“ entfernen. 
                  </p>
                  <h3> Übersicht </h3>
                  <p>
                  Die „Übersicht“ fasst für den Nutzer interessante Werte in einer Übersicht zusammen. 
                  Der aktuelle Promillewert wird bei jedem Hinzufügen eines Getränkes aktualisiert angezeigt. 
                  Der Höchststand des Alkoholgehalts wird samt Datum und Uhrzeit des Auftretend ebenfalls abgebildet. 
                  Darunter findet sich der Zeitpunkt, welcher als nächster einen Alkohol-Blutwert von 0,00‰ aufweist.
                  </p>
              </div>}
            </div>
          </div>
        </div>
      }
      </div>

    </div >
  );
}