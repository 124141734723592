import React, { useContext } from "react";
import { DrinkContext } from "../App.js"

//Hier den Löschenbutton später einbauen

export default function DrinkList() {
    const { drunkenDrinks, setDrunkenDrinks } = useContext(DrinkContext)

    function deleteEntry(index) {
        setDrunkenDrinks((pdrunkenDrinks) =>
            pdrunkenDrinks.filter((_, i) => i !== index)
        )
    }

    return (
        <div className="Liste" style={{ color: "white" }}>
            <h2>
                Ausgewählte Getränke
            </h2>
            <div className="NurListe" style={{ overflowY: 'scroll' }}>
                <table style={{ width: "100%", borderCollapse: "collapse", color: "white" }}>
                    <thead>
                        <tr>
                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Getränk</th>
                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Datum</th>
                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Uhrzeit</th>
                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Löschen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {drunkenDrinks.map((drink, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: "center", padding: "10px" }}>{drink.Anzeigename}</td>
                                <td style={{ textAlign: "center", padding: "10px" }}>{new Date(drink.Uhrzeit).toLocaleDateString([], { day: '2-digit', month: '2-digit' })}</td>
                                <td style={{ textAlign: "center", padding: "10px" }}>{new Date(drink.Uhrzeit).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
                                <td style={{ textAlign: "center", padding: "10px" }}>
                                    <button onClick={() => deleteEntry(index)} style={{ padding: "5px 10px", backgroundColor: "#ff4d4d", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}>
                                        Löschen
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )

}