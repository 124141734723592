import './App.css';
import UserData from './components/UserData.js';
import DropdownMenu from './components/DropdownMenu.js';
import DrinkList from './components/DrinkList.js'
import React, { useState, createContext, useEffect } from 'react';
import DrinkGraph from './components/DrinkGraph.js';
import Info from './components/Info.js';
import ErrorBox from './components/ErrorBox.js';




export const DrinkContext = createContext("");
export const UserContext = createContext("");
export const GraphDataPointContext = createContext("");
export const ErrorContext = createContext("")

function App() {

  const [graphDataPoints, setGraphDataPoints] = useState([]);
  const [drunkenDrinks, setDrunkenDrinks] = useState([])
  const [user, setUser] = useState("");
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("");
  const [isUserDataSubmitted, setUserDataSubmitted] = useState(false);
  const [listUndInfoZeigen, setListUndInfoZeigen] = useState(false)
  const [errorHandler, setErrorHandler] = useState({
    errorState: false,
    errorText: "",
    errorColor: "",
    errorType: ""
  })


  useEffect(() => {
    if (drunkenDrinks.length > 0 && listUndInfoZeigen == false) {
      setListUndInfoZeigen(true)
    }
  }, [drunkenDrinks]);

  useEffect(() => {
    if (drunkenDrinks.length == 0 && listUndInfoZeigen == true) {
      setListUndInfoZeigen(false)
    }
  }, [drunkenDrinks]);

  return (
    <div className='AppOverallDesign'>
      <ErrorContext.Provider value={{ errorHandler, setErrorHandler }}>
        <UserContext.Provider value={{ user, setUser, weight, setWeight, gender, setGender, isUserDataSubmitted, setUserDataSubmitted }}>
          <DrinkContext.Provider value={{ drunkenDrinks, setDrunkenDrinks }}>
            <GraphDataPointContext.Provider value={{ graphDataPoints, setGraphDataPoints }}>
              <UserData />
              {isUserDataSubmitted ?
                (
                  <div className='Body'>
                    <div className='EingabeV2'>
                      <DropdownMenu />
                    </div>

                    <DrinkGraph />
                    {listUndInfoZeigen ?
                      (
                        <div className='ListUndInfo'>
                          <DrinkList />
                          <Info />
                        </div>
                      ) : (
                        <div></div>
                      )};
                  </div>
                ) : (
                  <div></div>
                )}
              <div>
                {errorHandler.errorState == true &&
                  <ErrorBox/>
                }
              </div>
            </GraphDataPointContext.Provider>
          </DrinkContext.Provider>
        </UserContext.Provider>
      </ErrorContext.Provider>

    </div >
  );
}

export default App;
