import '../App.css';
import React, { useContext } from 'react';
import { ErrorContext } from "../App.js"


export default function Error() {

    const { errorHandler, setErrorHandler } = useContext(ErrorContext)
    const handleClick = () => {
        setErrorHandler({
            errorState: false,
            errorText: "",
            errorType: "",
            errorColor: "red",
        })
    }


    return (
        <div className='ErrorPage'>
            <div className='ErrorBox'>
                <div>
                    <h2 style = {{color: errorHandler.errorColor}}>
                        {errorHandler.errorType}
                    </h2>
                    <t1 color='black'>
                    <strong>{errorHandler.errorText}</strong>
                    </t1>
                </div>
                <div>
                    <button className='button-44' color={errorHandler.errorColor} onClick={handleClick}>
                        <strong>
                            OK! 
                        </strong>
                    </button>
                </div>
            </div>
        </div>
    )
}