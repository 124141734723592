import React, { useContext, useEffect, useState } from "react";
import { GraphDataPointContext, DrinkContext } from "../App";
import '../App.css';
import { roundToNearestMinute } from "../data/drinkClass"


export default function Info() {

    const { graphDataPoints, setGraphDataPoints } = useContext(GraphDataPointContext);

    const [FormatedDateNow, setFormatedDateNow] = useState(roundToNearestMinute(new Date()));
    const [maxPromilleDataPoint, setMaxPromilleDataPoint] = useState({
        promille:0,
        uhrzeit: FormatedDateNow
    });
    const [zeroPromilleDate, setZeroPromilleDate] = useState(null);
    const [aktuellePromille, setAktuellePromille] = useState(0);




    useEffect(() => {
        if (graphDataPoints.length > 0) {
            setZeroPromilleDate(soberedUpAt(graphDataPoints))
            setMaxPromilleDataPoint(getMaxPromilleDataPoint(graphDataPoints))
        };

    }, [graphDataPoints]);

    // AKTUALISIERT AKTUELLE PROMILLE MINÜTLICH UND EINMALIG BEIM AKTUALISIEREN VON GRAPH DATA
    useEffect(() => {
        const updatePromille = () => {
            const currentDate = roundToNearestMinute(new Date());
            setFormatedDateNow(currentDate);
            setAktuellePromille(getPromilleAtTime(graphDataPoints, currentDate));
        };
    
        // Initiale Aktualisierung beim Mounten (Änderung von graphData)
        updatePromille();
    
        // Intervall für wiederkehrende Aktualisierungen
        const intervalId = setInterval(updatePromille, 60000);
    
        return () => clearInterval(intervalId);
    }, [graphDataPoints]);
    
    function getMaxPromilleDataPoint(graphDataPointsLocal) {
        let maxPromille = 0
        let DataPointLocal = {
            promille: 0,
            uhrzeit: FormatedDateNow
        }

        graphDataPointsLocal.forEach(dataPoint => {
            if (dataPoint.promille > maxPromille) {
                maxPromille = dataPoint.promille
            };
        });

        if (maxPromille > 0) {
            graphDataPointsLocal.forEach(dataPoint => {
                if (dataPoint.promille == maxPromille) {
                    DataPointLocal = dataPoint
                };
            });
        };
        return DataPointLocal
    };

    function getPromilleAtTime(graphDataPointsLocal, Date) {
        let PromilleValue = 0

        graphDataPointsLocal.forEach(dataPoint => {
            if (dataPoint.uhrzeit.toString() == Date.toString()) {
                PromilleValue = Math.round(dataPoint.promille * 1000) / 1000 // Auf 3 Nachkommestellen gerundet weil JAVASCRIPT SCHNEEFLOCKE *1000

            };
        });
        return PromilleValue
    };

    function soberedUpAt(graphDataPointsLocal) {
        const soberedUpTime = graphDataPointsLocal[graphDataPointsLocal.length - 1].uhrzeit
        return soberedUpTime
    };



    return (
        <div className="Info">
            <h2 style={{color: "white"}}>
                Übersicht
            </h2>
            <div>
                <table style={{ width: "100%", borderCollapse: "collapse", color: "white" }}>
                    <thead>
                        <tr>
                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Aktueller Promillewert</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: "center", padding: "10px" }}>{aktuellePromille} ‰</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <table style={{ width: "100%", borderCollapse: "collapse", color: "white" }}>
                    <thead>
                        <tr>
                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Höchster Promillewert</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: "center", padding: "10px" }}>
                                {Math.round(maxPromilleDataPoint.promille*1000)/1000} ‰ | Am {new Date(maxPromilleDataPoint.uhrzeit).toLocaleDateString([], { day: '2-digit', month: '2-digit' })} | {new Date(maxPromilleDataPoint.uhrzeit).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} Uhr
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <table style={{ width: "100%", borderCollapse: "collapse", color: "white" }}>
                    <thead>
                        <tr>
                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                                Wieder Nüchtern
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: "center", padding: "10px" }}>
                                Am {new Date(zeroPromilleDate).toLocaleDateString([], { day: '2-digit', month: '2-digit' })} | {new Date(zeroPromilleDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} Uhr
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};