import * as drinkFunctions from './DBhandling.js'


export class drink {
    constructor(Anzeigename, Uhrzeit) {
        this.Anzeigename = Anzeigename
        this.drinkID = drinkFunctions.getDrinkIdByDisplayname(Anzeigename)
        this.Uhrzeit = roundToNearestMinute(Uhrzeit)
        this.alkMengeInGramm = drinkFunctions.getAlcoholAmountInGrammByID(this.drinkID)
        this.graphData = []
        console.log(this.alkMengeInGramm)
    }

    calcGraphData(gewicht, geschlecht) {
        let reduktionsfaktor
        let absorbtionszeit

        if (geschlecht == "männlich") {
            reduktionsfaktor = 0.68
        }
        else if (geschlecht == "weiblich") {
            reduktionsfaktor = 0.55
        }

        if (drinkFunctions.getDrinkTypeByID(this.drinkID) == "Shot") {
            absorbtionszeit = 20 //Minuten
        }
        else {
            absorbtionszeit = 60
        }

        const promillePeak = this.alkMengeInGramm / (gewicht * reduktionsfaktor);  // Theoretische maximale Promille (ohne Abbau)
        console.log(promillePeak)

        const datenPunkte = [];  // Array zum Speichern der Datenpunkte (Uhrzeit + Promille)
        
        // Absorptionsphase ohne Abbau
        for (let minute = 0; minute <= absorbtionszeit; minute++) {
            // Linearer Anstieg durch Absorption
            const absorptionPromille = (minute / absorbtionszeit) * promillePeak;

            const aktuelleZeit = new Date(this.Uhrzeit.getTime() + minute * 60000);  // Neue Uhrzeit
            datenPunkte.push({ uhrzeit: aktuelleZeit, promille: absorptionPromille });
        }

        this.graphData = datenPunkte
        return datenPunkte;  // Rückgabe der Datenpunkte (Uhrzeit + Promille)
    }

}

export function roundToNearestMinute(date) {
    var coeff = 1000 * 60 * 1;

    return new Date(Math.round(date.getTime() / coeff) * coeff);
}

export function getMaxEndTime(drinkArray) {
    let maxEndTime = null;

    drinkArray.forEach(drinkInstance => {
        const lastTime = drinkInstance.graphData[drinkInstance.graphData.length - 1].uhrzeit;  // Letzter Zeitpunkt des aktuellen Getränks

        if (!maxEndTime || lastTime > maxEndTime) {
            maxEndTime = lastTime;  // Setze den spätesten Endzeitpunkt
        }
    });

    return maxEndTime;
}
//Erstellen aller theoretischen Graphen eines drink Arrays. Daten werden im Objekt gespeichert
function calcArrayGraphData(drinkArray, gewicht, geschlecht) {
    drinkArray.forEach(drinkInstance => {
        drinkInstance.calcGraphData(gewicht, geschlecht)
    })
}

function extendGraphsOfArrayToMaxTime(drinkArray) {
    const maxEndTime = getMaxEndTime(drinkArray)
    drinkArray.forEach(drinkInstance => {
        
        const lastTime = drinkInstance.graphData[drinkInstance.graphData.length - 1].uhrzeit;  // Letzter Zeitpunkt des aktuellen Getränks
        const lastPromille = drinkInstance.graphData[drinkInstance.graphData.length - 1].promille;

        let time = new Date(lastTime.getTime() + 60000);  // Eine Minute nach dem letzten Wert

        // Füge horizontale Linien hinzu, bis der maximale Endzeitpunkt erreicht ist
        while (time <= maxEndTime) {
            drinkInstance.graphData.push({ uhrzeit: time, promille: lastPromille });
            time = new Date(time.getTime() + 60000);  // Gehe um eine Minute weiter
        }
    })
}

function calcCombinedGraphData(drinkArray){
    const combinedDataPoints = []

    drinkArray.forEach(drinkInstance => {
        drinkInstance.graphData.forEach(dataPoint => {
            const existingPoint = combinedDataPoints.find(dp => dp.uhrzeit.getTime() === dataPoint.uhrzeit.getTime());

            if (existingPoint) {
                existingPoint.promille += dataPoint.promille;
            } else {
                combinedDataPoints.push(dataPoint);
            }
        });
    });

    combinedDataPoints.sort((a, b) => a.uhrzeit - b.uhrzeit);  // Sortiere nach Zeit

    return combinedDataPoints;  // Rückgabe der kombinierten Datenpunkte
}

function AddLinearDegradationAfterLastDrink(GraphData, abbaurate){
    const LastDataPoint = GraphData[GraphData.length-1]
    let LastPromille = LastDataPoint.promille
    let LastTime = LastDataPoint.uhrzeit

    while (LastPromille > 0){
        LastTime = new Date(LastTime.getTime() + 60000)
        LastPromille -= abbaurate
        if (LastPromille < 0) LastPromille = 0

        GraphData.push({
            uhrzeit: LastTime,
            promille: LastPromille
        })
    }
    return GraphData
}


function addBodyAlcoholDegradation(GraphData, geschlecht){
    let abbaurate; // Pro Minute
    if (geschlecht == "männlich") {
        abbaurate = 0.15 / 60;  // 0.15 Promille pro Stunde auf Minutenbasis
        console.log(abbaurate)
    } else {
        abbaurate = 0.1 / 60;  // 0.1 Promille pro Stunde auf Minutenbasis
        console.log(abbaurate)
    }

    let TotalReduction = 0

    GraphData.forEach(DataPoint => {
        let newPromille = DataPoint.promille - TotalReduction

        if (newPromille <= 0){
            TotalReduction = DataPoint.promille + abbaurate
            DataPoint.promille = 0
        } else {
            DataPoint.promille = newPromille
            TotalReduction += abbaurate;
        }
    })

    const DegradedGraphData = AddLinearDegradationAfterLastDrink(GraphData,abbaurate)

    return DegradedGraphData

}


export function TransformDrinkArrayToGraph(drinkArray, gewicht, geschlecht){
    calcArrayGraphData(drinkArray, gewicht, geschlecht)
    extendGraphsOfArrayToMaxTime(drinkArray)

    const CombinedGraphWithoutDegradation = calcCombinedGraphData(drinkArray)
    const CombinedGraphWithDegradation = addBodyAlcoholDegradation(CombinedGraphWithoutDegradation, geschlecht)
    

    return CombinedGraphWithDegradation

}