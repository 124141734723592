import "../App.css"
import React, { useContext, useState, useEffect } from "react";
import { DrinkContext, ErrorContext } from "../App.js"
import { getUniqueTypes, initializeData, getDrinksByType } from "../data/DBhandling.js";
import { drink } from "../data/drinkClass.js";




export default function DropdownMenu() {

    const { drunkenDrinks, setDrunkenDrinks } = useContext(DrinkContext)
    const { errorHandler, setErrorHandler } = useContext(ErrorContext)

    const now = new Date();
    const [types, setTypes] = useState([])
    const [drinks, setDrinks] = useState([])
    const [drinkTime, setDrinkTime] = useState(new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString().slice(0, 16)); //Standard Wert Jetzt (Format für Input Feld weil es eine Schneeflocke ist...)
    const [selectedType, setSelectedType] = useState("");
    const [selectedDrink, setSelectedDrink] = useState([]);


    useEffect(() => {
        async function fetchData() {
            await initializeData();
            setTypes(getUniqueTypes())
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            setDrinks(getDrinksByType(selectedType))
        }
        fetchData();
    }, [selectedType]);

    function addDrink(drunkenDrinkLocal, timeLocal) {
        setDrunkenDrinks(drunkenDrinks => [...drunkenDrinks, new drink(drunkenDrinkLocal, new Date(timeLocal))])
        setErrorHandler({
            errorState: true,
            errorType: "Getränk hinzugefügt!",
            errorColor: "green",
        })
        setSelectedType("")
        setSelectedDrink("")
        setDrinkTime(new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString().slice(0, 16))

        setTimeout(() => {
            setErrorHandler({
                errorState: false,
                errorType: "",
                errorColor: "red",
            })
          }, 2000);
    }

    const chooseType = (event) => {
        setSelectedType(event.target.value)
    };

    const chooseDrink = (event) => {
        setSelectedDrink(event.target.value)
    };



    const handleClick = () => {

        if (selectedDrink == "" || drinkTime == "") {
            setErrorHandler({
                errorText: "Es muss ein Typ, ein Getränk und eine Uhrzeit ausgewählt werden!",
                errorState: true,
                errorType: "Unvollständige Getränkeauswahl",
                errorColor: "red"
            })
        }
        else {
            if (drunkenDrinks.length == 0) {
                addDrink(selectedDrink, drinkTime)
            }

            else {
                const allDates = []
                drunkenDrinks.forEach(drinkInstance => {
                    allDates.push(new Date(drinkInstance.Uhrzeit))
                })
                allDates.push(new Date(drinkTime))
                allDates.sort((a, b) => a - b) //Zeile sortiert Array in sich

                const ThreeDaysInMs = 2 * 24 * 60 * 60 * 1000;

                if (allDates[allDates.length - 1].getTime() - allDates[0].getTime() <= ThreeDaysInMs) {
                    addDrink(selectedDrink, drinkTime)
                }
                else {
                    setErrorHandler({
                        errorText: "Alle Getränkeeingaben müssen innerhalb von 2 Tagen (48 Stunden) liegen!",
                        errorState: true,
                        errorType: "Ungültiges Datum",
                        errorColor: "red"
                    })
                }
            }
        }
    }

    return (
        <div className="EingabeV2">
            <div style={{ color: "white" }}>
                <h2>
                    Getränkeauswahl
                </h2>
            </div>
            <div className="EingabeV2Children">
                <div>
                    <select className='input-style2' value={selectedType} onChange={chooseType}>
                        <option value="" disabled>
                            Typ
                        </option>
                        {types.map((drink, index) => (
                            <option key={index} value={drink}>
                                {drink}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <select className='input-style2' value={selectedDrink} onChange={chooseDrink}>
                        <option value="" disabled>
                            Getränk
                        </option>
                        {drinks.map((drink, index) => (
                            <option key={index} value={drink}>
                                {drink}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <input className='input-style2' type="datetime-local"
                        value={drinkTime}
                        onChange={(e) => setDrinkTime(e.target.value)} />
                </div>
                <div>
                    <button className='button-42' onClick={handleClick}>
                        Bestätigen!
                    </button>
                </div>
            </div>



        </div>

    )
}
