import * as aq from 'arquero';

// Funktion zum Laden der CSV-Datei im Browser
async function loadCSVData() {
    try {
        const response = await fetch('/drinks.csv');  // Die Datei ist im Public ordner
        const csvData = await response.text();  // Lies die CSV-Datei als Text
        const dt = aq.fromCSV(csvData);  // Verwende Arquero
        return dt; 
    } catch (error) {
        console.error('Fehler beim Laden der CSV-Datei:', error);
    }
}

// Alle Funktionen müssen nach dem Laden der CSV-Datei ausgeführt werden
let dt;

export async function initializeData() {
    dt = await loadCSVData();
}

export function getUniqueTypes() {
    if (!dt) return [];
    const uniqueTypes = dt.select('typ').dedupe().array('typ');
    return uniqueTypes;
}

export function getDrinksByType(type) {
    if (!dt) return [];
    const drinks = dt
        .params({ type })  
        .filter('d => d.typ === type')
        .select('anzeigename')
        .array('anzeigename');

    return drinks;
}

export function getDrinkTypeByID(id) {
    if (!dt) return null;
    const drink = dt
        .params({ id })
        .filter('d => d.id === id')
        .objects()[0];

    return drink ? drink.typ : null;
}

export function getDrinkIdByDisplayname(displayname) {
    if (!dt) return null;
    const drink = dt
        .params({ displayname })  
        .filter('d => d.anzeigename === displayname')
        .objects()[0];

    return drink ? drink.id : null;
}

export function getAlcoholAmountInGrammByID(id) {
    if (!dt) return null;
    const drink = dt
        .params({ id }) 
        .filter('d => d.id === id') 
        .objects()[0];

    if (!drink) {
        return null;
    }

    const alkoholgehalt = drink.alkoholgehalt;
    const menge = drink.menge;

    const alcoholInGrams = menge * alkoholgehalt * 0.789 * 1000;

    return alcoholInGrams;
}